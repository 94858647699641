import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'
import CustomAvatar from '../../Avatar'

import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectSolicitation } from '../../../action/solicitations'
import Button from '../../Button'

const PaperSolicitation = ({
    solicitation
}) => {
    const dispatch = useDispatch()

    const solicitationSelected = useSelector(state => state.solicitations.selected)

    const stages = {
        starting: "Iniciando",
        lookingForProvider: "Procurando prestador",
        in_progress: "Em deslocamento",
    }

    const servicesIcon = {
        "mecanico": MecanicoIcon,
        "bateria": BateriaIcon,
        "chaveiro": ChaveiroIcon,
        "combustivel": CombustivelIcon,
        "pneu": PneusIcon,
        "reboque": ReboqueIcon
    }

    const printAddress = () => {
        return `${solicitation.current_location.street}, ${solicitation.current_location.streetNumber}, ${solicitation.current_location.district}`
    }

    const printState = () => {
        return `${solicitation.current_location.city} - ${solicitation.current_location.state}`
    }

    return (
        <button 
            className={`paper-solicitation-container ${solicitation.uuid === solicitationSelected.uuid ? "active" : ""}`} 
            onClick={() => dispatch(selectSolicitation(solicitation))}
        >
            <div className='paper-solicitation-status-content'>
                <img src={servicesIcon[solicitation.service]}/>
                <span>{stages[solicitation.stage]}</span>
            </div>
            <div className='paper-solicitation-summary'>
                <span>{printAddress()}</span>
                <span>{printState()}</span>
                <span>{solicitation.vehicle}</span>
                <span>{solicitation.report}</span>
            </div>
        </button>
    )
}

export default PaperSolicitation