import { useEffect, useState } from "react";
import Layout from "../../../components/Layout";

import * as companiesService from '../../../services/comapnies'

import './styles.css'
import { useDispatch, useSelector } from "react-redux";
import { loadCompanies } from "../../../action/companies";
import PaperCompany from "../../../components/Paper/Company";
import Company from "./Company";

const Providers = () => {
    const dispatch = useDispatch()

    const companiesActive = useSelector(state => state.companies.active)
    const companiesInactive = useSelector(state => state.companies.inactive)
    const companySelected = useSelector(state => state.companies.selected)

    const getCompanies = async () => {
        const response = await companiesService.getAllCompanies()

        if (response) {
            dispatch(loadCompanies(response))
        }
    }

    useEffect(() => {
        getCompanies()
    }, [])

    return (
        <Layout>
            <div className="providers-container">
                <div className="providers-company-list-content">
                    <div className="providers-company-list-item">
                        <div className="providers-company-list-title">
                            <span>Empresas Ativas</span>
                            <label>{`(${companiesActive.length})`}</label>
                        </div>
                        <div className="providers-company-list">
                            <div className="providers-company-paper-group">
                                {companiesActive.length > 0 &&
                                    companiesActive.map((company, index) => {
                                        return <PaperCompany key={index} active company={company} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="providers-company-list-item">
                        <div className="providers-company-list-title">
                            <span>Empresas Inativas</span>
                            <label>{`(${companiesInactive.length})`}</label>
                        </div>
                        <div className="providers-company-list">
                            <div className="providers-company-paper-group">
                                {companiesInactive.length > 0 &&
                                    companiesInactive.map((company, index) => {
                                        return <PaperCompany key={index} company={company}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="providers-company-edition-content">
                    {Object.keys(companySelected).length > 0 ? (
                        <Company company={companySelected}/>
                    ) : (
                        <div className="providers-company-selected-empty">
                            <span>Selecione uma empresa</span>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Providers;