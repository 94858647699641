import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'


import 'react-spring-bottom-sheet/dist/style.css'
import './styles.css'
import PaperSolicitation from '../../Paper/Solicitation'

const BottomSheetMapping = ({
    open
}) => {
    const sheetRef = useRef()

    const solicitations = useSelector(state => Object.values(state.solicitations.list))

    return (
        <BottomSheet 
            blocking={false}
            className="bottom-sheet-container"
            ref={sheetRef}
            open={open}
            defaultSnap={({ maxHeight }) => maxHeight / 4}
            snapPoints={({ maxHeight, minHeight }) => [
                maxHeight - maxHeight / 10,
                maxHeight / 4,
                maxHeight * 0.6,
                minHeight
            ]}
        >
            <div className='bottom-sheet-mapping-content'>
                <span className='bottom-sheet-mapping-title'>Solicitações Ativas</span>
                <div>
                    {solicitations.length > 0 ? (
                        solicitations.map((item, index) => {
                            return (
                                <PaperSolicitation key={index} solicitation={item}/>
                            )
                        })
                    ) : (
                        <span className='bottom-sheet-mapping-subtitle'>Todas as solicitações foram atendidas</span>
                    )}
                </div>
            </div>
        </BottomSheet>
    )
}

export default BottomSheetMapping;