export const banks = [
    {
       "id":246,
       "label":"246 - Banco ABC Brasil S.A."
    },
    {
       "id":748,
       "label":"748 - Banco Cooperativo Sicredi S.A."
    },
    {
       "id":117,
       "label":"117 - Advanced Cc Ltda"
    },
    {
       "id":121,
       "label":"121 - Banco Agibank S.A."
    },
    {
       "id":172,
       "label":"172 - Albatross Ccv S.A"
    },
    {
       "id":188,
       "label":"188 - Ativa Investimentos S.A"
    },
    {
       "id":280,
       "label":"280 - Avista S.A. Crédito, Financiamento e Investimento"
    },
    {
       "id":80,
       "label":"80 - B&T Cc Ltda"
    },
    {
       "id":654,
       "label":"654 - Banco A.J.Renner S.A."
    },
    {
       "id":75,
       "label":"75 - Banco ABN AMRO S.A"
    },
    {
       "id":25,
       "label":"25 - Banco Alfa S.A."
    },
    {
       "id":641,
       "label":"641 - Banco Alvorada S.A."
    },
    {
       "id":65,
       "label":"65 - Banco Andbank (Brasil) S.A."
    },
    {
       "id":213,
       "label":"213 - Banco Arbi S.A."
    },
    {
       "id":96,
       "label":"96 - Banco B3 S.A."
    },
    {
       "id":24,
       "label":"24 - Banco BANDEPE S.A."
    },
    {
       "id":318,
       "label":"318 - Banco BMG S.A."
    },
    {
       "id":752,
       "label":"752 - Banco BNP Paribas Brasil S.A."
    },
    {
       "id":107,
       "label":"107 - Banco BOCOM BBM S.A."
    },
    {
       "id":63,
       "label":"63 - Banco Bradescard S.A."
    },
    {
       "id":36,
       "label":"36 - Banco Bradesco BBI S.A."
    },
    {
       "id":122,
       "label":"122 - Banco Bradesco BERJ S.A."
    },
    {
       "id":204,
       "label":"204 - Banco Bradesco Cartões S.A."
    },
    {
       "id":394,
       "label":"394 - Banco Bradesco Financiamentos S.A."
    },
    {
       "id":237,
       "label":"237 - Banco Bradesco S.A."
    },
    {
       "id":218,
       "label":"218 - Banco BS2 S.A."
    },
    {
       "id":208,
       "label":"208 - Banco BTG Pactual S.A."
    },
    {
       "id":336,
       "label":"336 - Banco C6 S.A – C6 Bank"
    },
    {
       "id":473,
       "label":"473 - Banco Caixa Geral – Brasil S.A."
    },
    {
       "id":412,
       "label":"412 - Banco Capital S.A."
    },
    {
       "id":40,
       "label":"40 - Banco Cargill S.A."
    },
    {
       "id":368,
       "label":"368 - Banco Carrefour"
    },
    {
       "id":266,
       "label":"266 - Banco Cédula S.A."
    },
    {
       "id":739,
       "label":"739 - Banco Cetelem S.A."
    },
    {
       "id":233,
       "label":"233 - Banco Cifra S.A."
    },
    {
       "id":745,
       "label":"745 - Banco Citibank S.A."
    },
    {
       "id":241,
       "label":"241 - Banco Clássico S.A."
    },
    {
       "id":756,
       "label":"756 - Banco Cooperativo do Brasil S.A. – BANCOOB"
    },
    {
       "id":222,
       "label":"222 - Banco Credit Agricole Brasil S.A."
    },
    {
       "id":505,
       "label":"505 - Banco Credit Suisse (Brasil) S.A."
    },
    {
       "id":69,
       "label":"69 - Banco Crefisa S.A."
    },
    {
       "id":3,
       "label":"3 - Banco da Amazônia S.A."
    },
    {
       "id":83,
       "label":"83 - Banco da China Brasil S.A."
    },
    {
       "id":707,
       "label":"707 - Banco Daycoval S.A."
    },
    {
       "id":51,
       "label":"51 - Banco de Desenvolvimento do Espírito Santo S.A."
    },
    {
       "id":300,
       "label":"300 - Banco de La Nacion Argentina"
    },
    {
       "id":495,
       "label":"495 - Banco de La Provincia de Buenos Aires"
    },
    {
       "id":494,
       "label":"494 - Banco de La Republica Oriental del Uruguay"
    },
    {
       "id":335,
       "label":"335 - Banco Digio S.A"
    },
    {
       "id":1,
       "label":"1 - Banco do Brasil S.A."
    },
    {
       "id":47,
       "label":"47 - Banco do Estado de Sergipe S.A."
    },
    {
       "id":37,
       "label":"37 - Banco do Estado do Pará S.A."
    },
    {
       "id":41,
       "label":"41 - Banco do Estado do Rio Grande do Sul S.A."
    },
    {
       "id":4,
       "label":"4 - Banco do Nordeste do Brasil S.A."
    },
    {
       "id":196,
       "label":"196 - Banco Fair Corretora de Câmbio S.A"
    },
    {
       "id":265,
       "label":"265 - Banco Fator S.A."
    },
    {
       "id":224,
       "label":"224 - Banco Fibra S.A."
    },
    {
       "id":626,
       "label":"626 - Banco Ficsa S.A."
    },
    {
       "id":94,
       "label":"94 - Banco Finaxis S.A."
    },
    {
       "id":612,
       "label":"612 - Banco Guanabara S.A."
    },
    {
       "id":12,
       "label":"12 - Banco Inbursa S.A."
    },
    {
       "id":604,
       "label":"604 - Banco Industrial do Brasil S.A."
    },
    {
       "id":653,
       "label":"653 - Banco Indusval S.A."
    },
    {
       "id":77,
       "label":"77 - Banco Inter S.A."
    },
    {
       "id":249,
       "label":"249 - Banco Investcred Unibanco S.A."
    },
    {
       "id":184,
       "label":"184 - Banco Itaú BBA S.A."
    },
    {
       "id":29,
       "label":"29 - Banco Itaú Consignado S.A."
    },
    {
       "id":479,
       "label":"479 - Banco ItauBank S.A"
    },
    {
       "id":376,
       "label":"376 - Banco J. P. Morgan S.A."
    },
    {
       "id":74,
       "label":"74 - Banco J. Safra S.A."
    },
    {
       "id":217,
       "label":"217 - Banco John Deere S.A."
    },
    {
       "id":76,
       "label":"76 - Banco KDB S.A."
    },
    {
       "id":757,
       "label":"757 - Banco KEB HANA do Brasil S.A."
    },
    {
       "id":600,
       "label":"600 - Banco Luso Brasileiro S.A."
    },
    {
       "id":243,
       "label":"243 - Banco Máxima S.A."
    },
    {
       "id":720,
       "label":"720 - Banco Maxinvest S.A."
    },
    {
       "id":389,
       "label":"389 - Banco Mercantil do Brasil S.A."
    },
    {
       "id":370,
       "label":"370 - Banco Mizuho do Brasil S.A."
    },
    {
       "id":746,
       "label":"746 - Banco Modal S.A."
    },
    {
       "id":66,
       "label":"66 - Banco Morgan Stanley S.A."
    },
    {
       "id":456,
       "label":"456 - Banco MUFG Brasil S.A."
    },
    {
       "id":7,
       "label":"7 - Banco Nacional de Desenvolvimento Econômico e Social – BNDES"
    },
    {
       "id":169,
       "label":"169 - Banco Olé Bonsucesso Consignado S.A."
    },
    {
       "id":111,
       "label":"111 - Banco Oliveira Trust Dtvm S.A"
    },
    {
       "id":79,
       "label":"79 - Banco Original do Agronegócio S.A."
    },
    {
       "id":212,
       "label":"212 - Banco Original S.A."
    },
    {
       "id":712,
       "label":"712 - Banco Ourinvest S.A."
    },
    {
       "id":623,
       "label":"623 - Banco PAN S.A."
    },
    {
       "id":611,
       "label":"611 - Banco Paulista S.A."
    },
    {
       "id":643,
       "label":"643 - Banco Pine S.A."
    },
    {
       "id":658,
       "label":"658 - Banco Porto Real de Investimentos S.A."
    },
    {
       "id":747,
       "label":"747 - Banco Rabobank International Brasil S.A."
    },
    {
       "id":633,
       "label":"633 - Banco Rendimento S.A."
    },
    {
       "id":741,
       "label":"741 - Banco Ribeirão Preto S.A."
    },
    {
       "id":120,
       "label":"120 - Banco Rodobens S.A."
    },
    {
       "id":422,
       "label":"422 - Banco Safra S.A."
    },
    {
       "id":33,
       "label":"33 - Banco Santander (Brasil) S.A."
    },
    {
       "id":743,
       "label":"743 - Banco Semear S.A."
    },
    {
       "id":754,
       "label":"754 - Banco Sistema S.A."
    },
    {
       "id":630,
       "label":"630 - Banco Smartbank S.A."
    },
    {
       "id":366,
       "label":"366 - Banco Société Générale Brasil S.A."
    },
    {
       "id":637,
       "label":"637 - Banco Sofisa S.A."
    },
    {
       "id":464,
       "label":"464 - Banco Sumitomo Mitsui Brasileiro S.A."
    },
    {
       "id":82,
       "label":"82 - Banco Topázio S.A."
    },
    {
       "id":634,
       "label":"634 - Banco Triângulo S.A."
    },
    {
       "id":18,
       "label":"18 - Banco Tricury S.A."
    },
    {
       "id":655,
       "label":"655 - Banco Votorantim S.A."
    },
    {
       "id":610,
       "label":"610 - Banco VR S.A."
    },
    {
       "id":119,
       "label":"119 - Banco Western Union do Brasil S.A."
    },
    {
       "id":124,
       "label":"124 - Banco Woori Bank do Brasil S.A."
    },
    {
       "id":348,
       "label":"348 - Banco Xp S/A"
    },
    {
       "id":81,
       "label":"81 - BancoSeguro S.A."
    },
    {
       "id":21,
       "label":"21 - BANESTES S.A. Banco do Estado do Espírito Santo"
    },
    {
       "id":755,
       "label":"755 - Bank of America Merrill Lynch Banco Múltiplo S.A."
    },
    {
       "id":268,
       "label":"268 - Barigui Companhia Hipotecária"
    },
    {
       "id":250,
       "label":"250 - BCV – Banco de Crédito e Varejo S.A."
    },
    {
       "id":144,
       "label":"144 - BEXS Banco de Câmbio S.A."
    },
    {
       "id":253,
       "label":"253 - Bexs Corretora de Câmbio S/A"
    },
    {
       "id":134,
       "label":"134 - Bgc Liquidez Dtvm Ltda"
    },
    {
       "id":17,
       "label":"17 - BNY Mellon Banco S.A."
    },
    {
       "id":301,
       "label":"301 - Bpp Instituição De Pagamentos S.A"
    },
    {
       "id":126,
       "label":"126 - BR Partners Banco de Investimento S.A."
    },
    {
       "id":70,
       "label":"70 - BRB – Banco de Brasília S.A."
    },
    {
       "id":92,
       "label":"92 - Brickell S.A. Crédito, Financiamento e Investimento"
    },
    {
       "id":173,
       "label":"173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A."
    },
    {
       "id":142,
       "label":"142 - Broker Brasil Cc Ltda"
    },
    {
       "id":292,
       "label":"292 - BS2 Distribuidora de Títulos e Valores Mobiliários S.A."
    },
    {
       "id":11,
       "label":"11 - C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)"
    },
    {
       "id":104,
       "label":"104 - Caixa Econômica Federal"
    },
    {
       "id":288,
       "label":"288 - Carol Distribuidora de Títulos e Valor Mobiliários Ltda"
    },
    {
       "id":130,
       "label":"130 - Caruana Scfi"
    },
    {
       "id":159,
       "label":"159 - Casa Credito S.A"
    },
    {
       "id":16,
       "label":"16 - Ccm Desp Trâns Sc E Rs"
    },
    {
       "id":89,
       "label":"89 - Ccr Reg Mogiana"
    },
    {
       "id":114,
       "label":"114 - Central Cooperativa De Crédito No Estado Do Espírito Santo"
    },
    {
       "id":"114-7",
       "label":"114-7 - Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda."
    },
    {
       "id":320,
       "label":"320 - China Construction Bank (Brasil) Banco Múltiplo S.A."
    },
    {
       "id":477,
       "label":"477 - Citibank N.A."
    },
    {
       "id":180,
       "label":"180 - Cm Capital Markets Cctvm Ltda"
    },
    {
       "id":127,
       "label":"127 - Codepe Cvc S.A"
    },
    {
       "id":163,
       "label":"163 - Commerzbank Brasil S.A. – Banco Múltiplo"
    },
    {
       "id":60,
       "label":"60 - Confidence Cc S.A"
    },
    {
       "id":85,
       "label":"85 - Coop Central Ailos"
    },
    {
       "id":97,
       "label":"97 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda."
    },
    {
       "id":"085-x",
       "label":"085-x - Cooperativa Central de Crédito Urbano-CECRED"
    },
    {
       "id":"090-2",
       "label":"090-2 - Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS"
    },
    {
       "id":"087-6",
       "label":"087-6 - Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná"
    },
    {
       "id":"089-2",
       "label":"089-2 - Cooperativa de Crédito Rural da Região da Mogiana"
    },
    {
       "id":286,
       "label":"286 - Cooperativa de Crédito Rural De Ouro"
    },
    {
       "id":279,
       "label":"279 - Cooperativa de Crédito Rural de Primavera Do Leste"
    },
    {
       "id":273,
       "label":"273 - Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel"
    },
    {
       "id":98,
       "label":"98 - Credialiança Ccr"
    },
    {
       "id":"098-1",
       "label":"098-1 - CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL"
    },
    {
       "id":10,
       "label":"10 - Credicoamo"
    },
    {
       "id":133,
       "label":"133 - Cresol Confederação"
    },
    {
       "id":182,
       "label":"182 - Dacasa Financeira S/A"
    },
    {
       "id":487,
       "label":"487 - Deutsche Bank S.A. – Banco Alemão"
    },
    {
       "id":140,
       "label":"140 - Easynvest – Título Cv S.A"
    },
    {
       "id":149,
       "label":"149 - Facta S.A. Cfi"
    },
    {
       "id":285,
       "label":"285 - Frente Corretora de Câmbio Ltda."
    },
    {
       "id":278,
       "label":"278 - Genial Investimentos Corretora de Valores Mobiliários S.A."
    },
    {
       "id":138,
       "label":"138 - Get Money Cc Ltda"
    },
    {
       "id":64,
       "label":"64 - Goldman Sachs do Brasil Banco Múltiplo S.A."
    },
    {
       "id":177,
       "label":"177 - Guide Investimentos S.A. Corretora de Valores"
    },
    {
       "id":146,
       "label":"146 - Guitta Corretora de Câmbio Ltda"
    },
    {
       "id":78,
       "label":"78 - Haitong Banco de Investimento do Brasil S.A."
    },
    {
       "id":62,
       "label":"62 - Hipercard Banco Múltiplo S.A."
    },
    {
       "id":189,
       "label":"189 - HS Financeira S/A Crédito, Financiamento e Investimentos"
    },
    {
       "id":269,
       "label":"269 - HSBC Brasil S.A. – Banco de Investimento"
    },
    {
       "id":271,
       "label":"271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
    },
    {
       "id":157,
       "label":"157 - Icap Do Brasil Ctvm Ltda"
    },
    {
       "id":132,
       "label":"132 - ICBC do Brasil Banco Múltiplo S.A."
    },
    {
       "id":492,
       "label":"492 - ING Bank N.V."
    },
    {
       "id":139,
       "label":"139 - Intesa Sanpaolo Brasil S.A. – Banco Múltiplo"
    },
    {
       "id":652,
       "label":"652 - Itaú Unibanco Holding S.A."
    },
    {
       "id":341,
       "label":"341 - Itaú Unibanco S.A."
    },
    {
       "id":488,
       "label":"488 - JPMorgan Chase Bank, National Association"
    },
    {
       "id":399,
       "label":"399 - Kirton Bank S.A. – Banco Múltiplo"
    },
    {
       "id":293,
       "label":"293 - Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda."
    },
    {
       "id":105,
       "label":"105 - Lecca Crédito, Financiamento e Investimento S/A"
    },
    {
       "id":145,
       "label":"145 - Levycam Ccv Ltda"
    },
    {
       "id":113,
       "label":"113 - Magliano S.A"
    },
    {
       "id":323,
       "label":"323 - Mercado Pago – Conta Do Mercado Livre"
    },
    {
       "id":128,
       "label":"128 - MS Bank S.A. Banco de Câmbio"
    },
    {
       "id":137,
       "label":"137 - Multimoney Cc Ltda"
    },
    {
       "id":14,
       "label":"14 - Natixis Brasil S.A. Banco Múltiplo"
    },
    {
       "id":191,
       "label":"191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda."
    },
    {
       "id":753,
       "label":"753 - Novo Banco Continental S.A. – Banco Múltiplo"
    },
    {
       "id":260,
       "label":"260 - Nu Pagamentos S.A (Nubank)"
    },
    {
       "id":613,
       "label":"613 - Omni Banco S.A."
    },
    {
       "id":290,
       "label":"290 - Pagseguro Internet S.A"
    },
    {
       "id":254,
       "label":"254 - Paraná Banco S.A."
    },
    {
       "id":326,
       "label":"326 - Parati – Crédito Financiamento e Investimento S.A."
    },
    {
       "id":194,
       "label":"194 - Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda"
    },
    {
       "id":174,
       "label":"174 - Pernambucanas Financ S.A"
    },
    {
       "id":100,
       "label":"100 - Planner Corretora De Valores S.A"
    },
    {
       "id":125,
       "label":"125 - Plural S.A. – Banco Múltiplo"
    },
    {
       "id":93,
       "label":"93 - Pólocred Scmepp Ltda"
    },
    {
       "id":108,
       "label":"108 - Portocred S.A"
    },
    {
       "id":283,
       "label":"283 - Rb Capital Investimentos Dtvm Ltda"
    },
    {
       "id":101,
       "label":"101 - Renascenca Dtvm Ltda"
    },
    {
       "id":270,
       "label":"270 - Sagitur Corretora de Câmbio Ltda."
    },
    {
       "id":751,
       "label":"751 - Scotiabank Brasil S.A. Banco Múltiplo"
    },
    {
       "id":276,
       "label":"276 - Senff S.A. – Crédito, Financiamento e Investimento"
    },
    {
       "id":545,
       "label":"545 - Senso Ccvm S.A"
    },
    {
       "id":190,
       "label":"190 - Servicoop"
    },
    {
       "id":183,
       "label":"183 - Socred S.A"
    },
    {
       "id":299,
       "label":"299 - Sorocred Crédito, Financiamento e Investimento S.A."
    },
    {
       "id":118,
       "label":"118 - Standard Chartered Bank (Brasil) S/A–Bco Invest."
    },
    {
       "id":197,
       "label":"197 - Stone Pagamentos S.A"
    },
    {
       "id":340,
       "label":"340 - Super Pagamentos e Administração de Meios Eletrônicos S.A."
    },
    {
       "id":95,
       "label":"95 - Travelex Banco de Câmbio S.A."
    },
    {
       "id":143,
       "label":"143 - Treviso Corretora de Câmbio S.A."
    },
    {
       "id":131,
       "label":"131 - Tullett Prebon Brasil Cvc Ltda"
    },
    {
       "id":129,
       "label":"129 - UBS Brasil Banco de Investimento S.A."
    },
    {
       "id":"091-4",
       "label":"091-4 - Unicred Central do Rio Grande do Sul"
    },
    {
       "id":91,
       "label":"91 - Unicred Central Rs"
    },
    {
       "id":136,
       "label":"136 - Unicred Cooperativa"
    },
    {
       "id":99,
       "label":"99 - UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda."
    },
    {
       "id":84,
       "label":"84 - Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências"
    },
    {
       "id":298,
       "label":"298 - Vips Cc Ltda"
    },
    {
       "id":310,
       "label":"310 - Vortx Distribuidora de Títulos e Valores Mobiliários Ltda"
    },
    {
       "id":102,
       "label":"102 - Xp Investimentos S.A"
    }
 ]