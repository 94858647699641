import api from '../services/api';

export const getAllCompanies = async () => {
    try {
        const response = await api.get('/company/getAllCompanies')
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getAllCompanies', error.message)
        return false
    }
}

export const updateCompany = async (newCompany) => {
    try {
        const response = await api.put('company', newCompany)
        if (response.status === 200) 
            return true
        return false
    } catch(error) {
        console.log(error)
    }
}