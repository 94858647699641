import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { pushSolicitation, setLoading } from '../../action/solicitations';

import * as solicitationsService from '../../services/solicitations'

import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';

import CustomTableRow from './Row';

const CustomTable = ({
    columns,
    values,
    total,
    loading
}) => {
    const dispatch = useDispatch()

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('createAt');

    const formatColunsName = {
        uuid: "Solicitação",
        createAt: "Criada em",
        service: "Tipo",
        status: "Status",
        stage: "Sub Status",
        paymentStatus: "Pagamento",
        difficulty: "Dificuldade"
    }

    const handleChangePage = async (event, newPage) => {
        dispatch(setLoading(true))
        const response = await solicitationsService.getAllSolicitations({
            page: newPage,
            rowsPerPage: rowsPerPage
        })

        if (response) {
            dispatch(pushSolicitation(response))
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () => stableSort(values, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage, values],
    );

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
                <Table aria-label="collapsible table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {columns.map((col, index) => {
                                return (
                                    <TableCell
                                        key={index}
                                        sortDirection={orderBy === col ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === col}
                                            direction={orderBy === col ? order : 'asc'}
                                            onClick={createSortHandler(col)}
                                        >
                                            <strong>{formatColunsName[col]}</strong>
                                        </TableSortLabel>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell>
                                    <span>Carregando informações...</span>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        ) : (
                            visibleRows.map((row, index) => {
                                return (
                                    <CustomTableRow key={index} row={row} columns={columns}/>
                                )
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                page={page}
                component="div"
                count={total}
                labelRowsPerPage="Linhas"
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}
export default CustomTable;