import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import './styles.css'
import { openModal } from "../../../action/modal"
import InputText from "../../Input/Text"
import Button from "../../Button"
import { useState } from "react"
import { usePdfGenerator } from "../../../hooks/usePdfGenerator"

const OrderServiceModal = () => {
    const dispatch = useDispatch()
    const { generateOrderServie } = usePdfGenerator()

    const order = useSelector(state => state.orderService.order)
    const modalControler = useSelector(state => state.modal.orderService)

    const [customerType, setCustomerType] = useState()
    const [consumer, setConsumerData] = useState({
        name: "",
        document: "",
        tell: "",
        email: ""
    })

    const documentOption = [
        {label: "Pessoa Física", id: "documentPF"},
        {label: "Pessoa Juridica", id: "documentPJ"}
    ]

    const handleConsumerData = (column, value) => {
        setConsumerData({
            ...consumer,
            [column]: value
        })
    }

    const onClose = () => {
        dispatch(openModal({modal: "orderService", value: false}))
    }

    const handleGenerateOrderService = () => {
        generateOrderServie({
            ...order,
            user: consumer
        })
    }

    return (
        <Modal
            open={modalControler}
            onClose={onClose}
        >
            <div className="modal-order-service-container">
                <div className="modal-order-service-title-content">
                    <h2>Gerar ordem de serviço</h2>
                </div>
                <div className="modal-order-service-content">
                    <div className="new-value-content">
                        <span>Dados do cliente</span>
                        <InputText
                            label="Nome completo"
                            value={consumer.name}
                            onChange={(event) => handleConsumerData("name", event.target.value)}
                            style={{marginTop: 8, marginBottom: 8}}
                        />
                        <div>
                            <div>
                                {documentOption.map((item, index) => {
                                    return (
                                        <button 
                                            key={item.id} 
                                            onClick={() => setCustomerType(item.id)}
                                            className={`modal-order-service-button ${item.id === customerType ? "active" : "inactive"}`}
                                            style={{marginRight: 8}}
                                        >
                                            {item.label}
                                        </button>
                                    )
                                })}
                            </div>
                            <InputText
                                label={customerType === "documentPF" ? "Digite o CPF" : "Digite o CNPJ"}
                                mask={customerType}
                                value={consumer.document}
                                onChange={(event) => handleConsumerData("document", event.target.value)}
                                style={{marginTop: 12, marginBottom: 8}}
                            />
                        </div>
                        <InputText
                            label="Telefone"
                            mask="cellphone"
                            value={consumer.tell}
                            onChange={(event) => handleConsumerData("tell", event.target.value)}
                            style={{marginTop: 8, marginBottom: 8}}
                        />
                        <InputText
                            label="E-mail"
                            value={consumer.email}
                            onChange={(event) => handleConsumerData("email", event.target.value)}
                            style={{marginTop: 8, marginBottom: 8}}
                        />
                    </div>
                    <Button 
                        label="Gerar ordem de serviço"
                        style={{alignSelf: "center"}}
                        onClick={handleGenerateOrderService}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default OrderServiceModal