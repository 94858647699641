import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { useAuth } from "../../hooks/useAuth";

import CustomAvatar from "../Avatar";

import './styles.css'

const Layout = ({
    children
}) => {
    const { user, logout } = useAuth()
    const navigate = useNavigate()

    const menu = [
        {id: "leads", label: "Leads", navigateTo: "/leads"},
        {id: "solicitation", label: "Solicitações", navigateTo: "/solicitacoes"},
        {id: "newSolicitation", label: "Criar Solicitação", navigateTo: "/nova-solicitacao"},
        {id: "mapping", label: "Mapeamento", navigateTo: "/mapeamento"},
        {id: "providers", label: "Prestadores", navigateTo: "/prestadores"},
    ]

    const getUserInfo = (column) => {
        const userInfo = jwtDecode(user.credential)
        return userInfo[column]
    }

    return (
        <div>
            <header className="layout-header-container">
                <div className="layout-header-avatar-container">
                    <div className="layout-header-avatar-content">
                        <CustomAvatar label={getUserInfo("name")}/>
                        <div className="layout-header-user-content">
                            <label>{getUserInfo("name")}</label>
                            <span>{getUserInfo("email")}</span>
                        </div>
                    </div>
                    <button 
                        style={{marginLeft: 16}}
                        className="layout-button"
                        onClick={logout}
                    >
                        Sair
                    </button>
                </div>
                <div className="layout-header-menu-container">
                    {menu.map((item, index) => {
                        return (
                            <button 
                                key={index}
                                className={`layout-button ${window.location.pathname === item.navigateTo ? "active" : ""}`}
                                onClick={() => navigate(item.navigateTo)} 
                            >
                                {item.label}
                            </button>
                        )
                    })}
                </div>
            </header>
            {children}
        </div>
    )
}

export default Layout;