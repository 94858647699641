import { useDispatch, useSelector } from "react-redux";
import BottomSheetMapping from "../../../components/BottomSheets/Mapping";
import Layout from "../../../components/Layout"
import MapView from "../../../components/MapView"
import { useEffect } from "react";
import * as solicitationsService from '../../../services/solicitations'
import { insertSolicitation } from "../../../action/solicitations";
import * as webSocket from '../../../services/wss'
import { setWebSocketConnection, setWebSocketReady } from "../../../action/webScocket";
import { updateProvider } from "../../../action/providers";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { jwtDecode } from "jwt-decode";
import RedirectServiceModal from "../../../components/Modal/RedirectService";

const Mapping = () => {
    const [user, setUser, getUser] = useLocalStorage()
    const origin = {lat: -19.9309777, lng: -43.9507109}

    const dispatch = useDispatch()

    const providers = useSelector(state => Object.values(state.providers.list))
    const solicitation = useSelector(state => state.solicitations.selected)
    const connectionWss = useSelector(state => state.webSocket)

    const startWebsocket = (user) => {
        const connection = webSocket.connect(user, "admin")
        connection.onopen = () => { dispatch(setWebSocketReady(true)); };

        connection.onmessage = async (event) => {
            const data = JSON.parse(event.data)

            if (data.action === "sendLocation") {
                dispatch(updateProvider(data))
            }
        }
        
        dispatch(setWebSocketConnection(connection))
    }

    useEffect(() => {
        (async () => {
            const response = await solicitationsService.getAllActiveSolicitations()
            if (response) {
                dispatch(insertSolicitation(response))
            }
        })()
    }, [])

    useEffect(() => {
        if(!connectionWss.ready) {
            const userLocal = getUser("user")
            const user = jwtDecode(userLocal.credential)
            startWebsocket(user.email)
        }
    }, [])
    
    return (
        <>
            <RedirectServiceModal />
            <Layout>
                <MapView origin={origin} providers={providers} solicitation={solicitation}/>
                <BottomSheetMapping open={true}/>
            </Layout>
        </>
    );
}

export default Mapping;