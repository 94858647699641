import { createSlice } from '@reduxjs/toolkit'

const calculateDifficulty = (solicitation) => {
    if (solicitation.service === "pneu") {
        const tiresConfig = solicitation.tiresConfig

        // Pneu furado
        if (tiresConfig.tire_conditions === "furado") {
            if (
                tiresConfig.has_steppe === "não" ||
                (tiresConfig.has_steppe === "sim" && tiresConfig.steppe_is_full === "não")
            ) return "moderate"
        }

        // Pneu rasgado e estourado
        if (["rasgado", "estourado"].includes(tiresConfig.tire_conditions)) {
            if (
                tiresConfig.has_steppe === "sim" && 
                tiresConfig.steppe_is_full === "não"
            ) return "moderate"

            if (tiresConfig.has_steppe === "não") return "complex"
        }
        
        return "simple"
    } else if (solicitation.service === "chaveiro") {
        if (solicitation.vehicleType !== "Auto") return "complex"
        else if (solicitation.vehicleOrigin !== "NACIONAL") return "moderate"
        else if (
            solicitation.keyConfig.condition === "quebrada" &&
            solicitation.keyConfig.hasExtrakey === "sim"
        ) return "moderate"
        else if (
            solicitation.keyConfig.condition === "quebrada" &&
            solicitation.keyConfig.hasExtrakey === "não"
        ) return "complex"
        return "simple"
    }
    return "simple"
}

export const newSolicitationSlice = createSlice({
    name: 'newSolicitation',
    initialState: {
        current_location:{
            street: "",
            streetNumber: "",
            district: "",
            latitude:0,
            longitude:0
        },
        destinyLocation:{
            street: "",
            streetNumber: "",
            district: "",
            latitude:0,
            longitude:0
        },
        paymentId:"",
        price:0,
        fee: 0,
        service:"",
        status:"new",
        tiresConfig:{
            tire_conditions:"",
            has_steppe: "",
            tire_height: "",
            has_tools: "",
            steppe_is_full: ""
        },
        keyConfig:{
            condition:"",
            hasExtrakey: "",
        },
        wheelsConfig: {
            condition: ""
        },
        vehicle:"",
        vehicleCategory: "",
        placa: "",
        report:"",
        cellphone: "",
        origin: "whatsapp",
        uuidLead: null,
        uuidUser: null,
        difficulty: "",
        type: "service"
    },
    reducers: {
        recreateSolicitation: (state, action) => {
            const { payload } = action

            state.current_location = payload.current_location
            state.destinyLocation = payload.destinyLocation
            state.price = payload.price
            state.service = payload.service
            state.status = payload.status
            state.tiresConfig = payload.tiresConfig
            state.vehicle = payload.vehicle
            state.report = payload.report
            state.origin = payload.origin
            state.uuidLead = payload.uuidLead
            state.uuidUser = payload.uuidUser
            state.uuid = payload.uuid
            state.validateCode = payload.validateCode
            state.createAt = payload.createAt
            state.stage = payload.stage
            state.paymentId = payload.paymentId
            state.canceledAt = payload.canceledAt
            state.isCapturePayments = payload.isCapturePayments
            state.capturePaymentsValue = payload.capturePaymentsValue
            state.difficulty = payload.difficulty
            state.paymentStatus = payload.paymentStatus
        },

        changeSolicitation: (state, action) => {
            const { payload } = action
            state[payload.column] =  payload.value

            if (payload.column === "service") {
                if (["bateria", "combustivel"].includes(payload.value)) state.difficulty = "simple"
                else if (["pneu", "chaveiro"].includes(payload.value)) state.difficulty = ""
                else if (payload.value !== "pneu") state.difficulty = "moderate"
            }

            if (payload.column === "service" && payload.value !== "pneu") {
                state.tiresConfig = {
                    tire_conditions:"",
                    has_steppe: "",
                    tire_height: "",
                    has_tools: "",
                    steppe_is_full: ""
                }
            }

            if (payload.column === "paymentMethod") {
                if (payload.value !== "pix") state.paymentStatus = "pending"
                else {
                    state.paymentStatus = "success"
                    state.paymentId = "indefinido"
                }
            }

            if (
                payload.column === "vehicleCategory" &&
                state.tiresConfig.has_steppe !== "" &&
                state.tiresConfig.has_tools !== "" &&
                state.tiresConfig.steppe_is_full !== "" &&
                state.tiresConfig.tire_conditions !== ""
            ) {
                state.difficulty = calculateDifficulty(state)
            }
        },

        changeCurrentLocation: (state, action) => {
            const { payload } = action
            state.current_location =  {
                ...state.current_location,
                [payload.column]: payload.value
            }
        },

        changeDestinyLocation: (state, action) => {
            const { payload } = action
            state.destinyLocation =  {
                ...state.destinyLocation,
                [payload.column]: payload.value
            }
        },

        changeTiresConfig: (state, action) => {
            const { payload } = action
            state.tiresConfig =  {
                ...state.tiresConfig,
                [payload.column]: payload.value
            }

            if (
                state.tiresConfig.has_steppe !== "" &&
                state.tiresConfig.has_tools !== "" &&
                state.tiresConfig.tire_conditions !== "" &&
                ((state.tiresConfig.has_steppe === "não" && state.tiresConfig.steppe_is_full === "") || state.tiresConfig.steppe_is_full !== "")
            ) {
                state.difficulty = calculateDifficulty(state)
            }
        },

        changeVehicle: (state, action) => {
            const { payload } = action

            state.vehicle = payload.vehicle
            state.vehicleCategory = payload.vehicleCategory
            state.vehicleOrigin = payload.vehicleOrigin
            state.vehicleType = payload.vehicleType

            if (payload.service === "penu") {
                state.difficulty = calculateDifficulty(state)
            } else if (payload.service === "chaveiro" && payload.vehicleType === "Moto") {
                state.difficulty = calculateDifficulty(state)
                state.keyConfig.condition = ""
                state.keyConfig.hasExtrakey = ""
            } else if (payload.service === "chaveiro" && payload.vehicleType !== "Moto") {
                state.difficulty = ""
                state.keyConfig.condition = ""
                state.keyConfig.hasExtrakey = ""
            }
        },

        changeKeyConfig: (state, action) => {
            const { payload } = action
            state.keyConfig =  {
                ...state.keyConfig,
                [payload.column]: payload.value
            }
            
            if (
                (
                    state.keyConfig.condition === "quebrada" &&
                    state.keyConfig.hasExtrakey !== ""
                ) ||
                state.keyConfig.condition === "inVehicle"
            ) state.difficulty = calculateDifficulty(state)
        },
        changeWheelsConditions: (state, action) => {
            const { payload } = action
            state.wheelsConfig = {
                ...state.wheelsConfig,
                [payload.column]: payload.value
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    changeWheelsConditions,
    changeCurrentLocation,
    changeDestinyLocation,
    recreateSolicitation,
    changeSolicitation,
    changeTiresConfig,
    changeKeyConfig,
    changeVehicle,
    changePrice
} = newSolicitationSlice.actions

export default newSolicitationSlice.reducer