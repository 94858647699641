import { createSlice } from '@reduxjs/toolkit'

export const providersSlice = createSlice({
    name: "webSocket",
    initialState: {
        selected: {},
        list: {}
    },
    reducers: {
        updateProvider: (state, action) => {
            const { payload } = action

            state.list[payload.uuid] = action.payload
        },
        selectProvider: (state, action) => {
            state.selected = action.payload
        },
    }
})
// Action creators are generated for each case reducer function
export const { 
    updateProvider,
    selectProvider
} = providersSlice.actions

export default providersSlice.reducer