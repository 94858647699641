import { createSlice } from '@reduxjs/toolkit'

export const webSocketSlice = createSlice({
    name: "webSocket",
    initialState: {
        ready: false,
        connection: {
            readyState: WebSocket.CLOSED
        }
    },
    reducers: {
        setWebSocketConnection: (state, action) => {
            state.connection = action.payload
        },
        setWebSocketReady: (state, action) => {
            state.ready = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    setWebSocketConnection,
    setWebSocketReady
} = webSocketSlice.actions

export default webSocketSlice.reducer