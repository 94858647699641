import CircularProgress from '@mui/material/CircularProgress';

import './styles.css'

const Button = ({
    label,
    onClick,
    disabled,
    style,
    kind,
    loading,
    fullwidth
}) => {
    return (
        <button
            style={style}
            disabled={disabled || loading}
            className={`keeper-button ${kind ? kind : "" } ${disabled ? "disabled" : ""} ${loading ? "disabled" : ""} ${fullwidth ? "fullwidth" : ""}`}
            onClick={onClick}
        >   
            <div className='keeper-button-label'>
                {label}
                {loading && <CircularProgress size={12} sx={{color: "white", marginLeft: 1}}/>}
            </div>
        </button>
    )
}

export default Button;