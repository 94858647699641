import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../../hooks/useAuth"

import CustomAvatar from "../../Avatar"
import * as event from '../../../services/event'

import './styles.css'

const PaperLead = ({lead, active, onClick}) => {
    const { user } = useAuth()

    const handleOpenWhatsApp = () => {
        const userDecode = jwtDecode(user.credential)
        
        const url = `https://wa.me/${lead.cellphone.replace(/[^0-9]/g, '')}`
        window.open(url, '_blank')

        event.registerEvent({
            type: "contactedCustomer",
            uuidLead: lead.uuid,
            admin: userDecode.email
        })
    };

    return (
        <button onClick={onClick} className={`paper-leads-container ${active && "active"}`}>
            <div className="paper-leads-avatar-content">
                <CustomAvatar label={lead.name ? lead.name : "Não Informado"} size={36}/>
                <div className="paper-leads-info">
                    <label>{lead.name ? lead.name : "Não Informado"}</label>
                    <span>{lead.cellphone && lead.cellphone}</span>
                </div>
            </div>
            {lead.cellphone &&
                <div style={{display: "flex"}}>
                    <a 
                        target="_blank"
                        className="paper-leads-icon-button" 
                        onClick={handleOpenWhatsApp}
                    />
                </div>
            }
        </button>
    )
}
export default PaperLead