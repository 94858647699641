import { createSlice } from '@reduxjs/toolkit'

export const servicesSlice = createSlice({
    name: 'services',
    initialState: {
        auto: []
    },
    reducers: {
        loadServices: (state, action) => {
            const { payload } = action
            
            state.auto = payload.filter(item => item.type === "auto")
        }
    }
})

// Action creators are generated for each case reducer function
export const { loadServices } = servicesSlice.actions

export default servicesSlice.reducer