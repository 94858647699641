import { TableCell, TableRow } from "@mui/material"

const CustomEventTableRow = ({ row, columns }) => {
    const printJson = (value) => {
        return JSON.stringify(value, null, 4)
    }

    return (
        <TableRow>
            {columns.map((col, index) => {
                if (col === "createdAt") return <TableCell key={index}>{`${new Date(row[col]).toLocaleDateString()} ${new Date(row[col]).toLocaleTimeString()}`}</TableCell>
                if (col === "eventData") return <TableCell key={index}><pre style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>{printJson(row[col])}</pre></TableCell>
                return <TableCell key={index}>{row[col]}</TableCell>
            })}
        </TableRow>
    )
}

export default CustomEventTableRow