import  {
    APIProvider,
    Map
} from '@vis.gl/react-google-maps'

import { Marker } from './marker'

const MapView = ({
    origin,
    providers,
    solicitation
}) => {

    return (
        <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
            <div style={{height: "100vh", width: "100%"}}>
                <Map 
                    defaultCenter={origin} 
                    defaultZoom={12}
                    mapId="mapping"
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {providers && providers.map((item, index) => {
                        const location = {
                            lat: item.location.latitude,
                            lng: item.location.longitude
                        }

                        return (
                            <Marker key={index} provider={item} position={location} icon="mecanico"/>
                        )
                    })}

                    {solicitation && Object.keys(solicitation).length > 0 &&
                        <Marker 
                            icon="carro"
                            position={{
                                lat: solicitation.current_location.latitude,
                                lng: solicitation.current_location.longitude
                            }}
                        />
                    }
                </Map>
            </div>
        </APIProvider>
    )
}
export default MapView