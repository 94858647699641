import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'

import './styles.css'

const PaperSolicitationSummury = ({
    active,
    solicitation,
    onSelected
}) => {
    const stages = {
        starting: "Iniciando",
        lookingForProvider: "Procurando prestador",
        in_progress: "Em deslocamento",
    }

    const servicesIcon = {
        "mecanico": MecanicoIcon,
        "bateria": BateriaIcon,
        "chaveiro": ChaveiroIcon,
        "combustivel": CombustivelIcon,
        "pneu": PneusIcon,
        "reboque": ReboqueIcon
    }

    return (
        <button 
            onClick={onSelected}
            className={`paper-solicitation-summury-container ${active ? "active": "inactive"}`}
        >
            <img src={servicesIcon[solicitation.service]}/>
            <div className="paper-solicitation-summury-content">
                <strong>{stages[solicitation.stage]}</strong>
                <span>{solicitation.vehicle}</span>
                <span>{solicitation.current_location.formattedAddress}</span>
            </div>
        </button>
    )
}

export default PaperSolicitationSummury