import jsPDF from "jspdf"
import "jspdf-autotable"

export const usePdfGenerator = () => {
    
    const servicesLabel = {
        mecanico: 'Mecânico',
        bateria: 'Carga de Bateria',
        chaveiro: 'Chaveiro',
        combustivel: 'Combustível',
        pneu: 'Troca de Pneus',
        reboque: "Reboque"
    }

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Pega o dia e adiciona zero à esquerda se necessário
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pega o mês (lembre-se que janeiro é 0)
        const year = date.getFullYear(); // Pega o ano completo
      
        return `${day}/${month}/${year}`;
    };

    const generateOrderServie = (clientData) => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();

        // header
        const positionHeader = 10
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Keeper Assistências", 10, positionHeader);
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("CNPJ: 49.756.890/0001-55", 10, positionHeader + 6);
        doc.text("keeper@keeper.tec.br", pageWidth - 10, positionHeader, { align: "right" });

        // cliente
        const positionClientStart = 26
        doc.setFontSize(16);
        doc.text("Dados do Cliente", 10, positionClientStart); // Título
        doc.setLineWidth(0.25);  // Define a espessura da linha
        doc.setDrawColor(169, 169, 169); // Define a cor da linha (cinza claro)
        doc.line(10, positionClientStart + 2, pageWidth - 10, positionClientStart + 2);  // Desenha a linha (x1, y1, x2, y2)
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(clientData.user.name, 10, positionClientStart + 10);
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`CPF/CNPJ: ${clientData.user.document}`, 10, positionClientStart + 16);
        doc.text(`Telefone: ${clientData.user.tell}`, 10, positionClientStart + 21);
        doc.text(`Email: ${clientData.user.email}`, 10, positionClientStart + 26);
        doc.text(`Data: ${`${new Date(clientData.createAt).toLocaleDateString()}`}`, pageWidth - 10, positionClientStart + 26, { align: "right" });

        // orderTitle
        const positionTitleStart = 58
        const headerHeight = 6;  // Altura do cabeçalho
        doc.setFillColor("#009CF5");  // Cor de fundo
        doc.rect(10, positionTitleStart, pageWidth - 20, headerHeight, "F");  // Desenha o retângulo preenchido (F)
        doc.setFontSize(10);
        doc.setTextColor(255, 255, 255);  // Define a cor do texto como branco
        doc.text(`ORDEM DE SERVIÇO Nº ${clientData.uuid}`, pageWidth / 2, positionTitleStart + 4, { align: "center" });  // Texto centralizado
        doc.setTextColor(0, 0, 0);

        // vehicle
        const positionVehicleStart = 74
        doc.setFontSize(16);
        doc.text("Veículo", 10, positionVehicleStart); // Título
        doc.setLineWidth(0.25);  // Define a espessura da linha
        doc.setDrawColor(169, 169, 169); // Define a cor da linha (cinza claro)
        doc.line(10, positionVehicleStart + 2, pageWidth - 10, positionVehicleStart + 2);  // Desenha a linha (x1, y1, x2, y2)

        const vehicle = [clientData.vehicle.split(" - ")]
        doc.autoTable({
            startY: positionVehicleStart + 4,
            head: [['Marca', 'Modelo', 'Cor', 'Placa']],
            body: vehicle,
            margin: { left: 10, right: 10 },
            headStyles: {
                fillColor: [0, 156, 245], // cor de fundo do cabeçalho
                textColor: [255, 255, 255], // cor do texto do cabeçalho
            }
        });

        // service
        const positionServiceStart = 102
        doc.setFontSize(16);
        doc.text("Serviço", 10, positionServiceStart); // Título
        doc.setLineWidth(0.25);  // Define a espessura da linha
        doc.setDrawColor(169, 169, 169); // Define a cor da linha (cinza claro)
        doc.line(10, positionServiceStart + 2, pageWidth - 10, positionServiceStart + 2);  // Desenha a linha (x1, y1, x2, y2)
        const services = [{
            name: servicesLabel[clientData.service],
            quantity: 1,
            unit: "UN",
            //fee: clientData.fee,
            //providerPrice: (clientData.price - clientData.fee),
            totalPrice: clientData.price
        }]
        doc.autoTable({
            startY: positionServiceStart + 4,
            head: [[
                'Serviço',
                'Quantidade',
                'Unidade',
                //'Valor do Serviço',
                //'Taxa Keeper',
                'Valor Total'
            ]],
            body: services.map(service => [
                service.name, 
                service.quantity, 
                service.unit, 
                //service.providerPrice, 
                //service.fee, 
                Number(service.totalPrice).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
            ]),
            margin: { left: 10, right: 10 },
            headStyles: {
                fillColor: [0, 156, 245], // cor de fundo do cabeçalho
                textColor: [255, 255, 255], // cor do texto do cabeçalho
            }
        });

        // Adicionar subtotal e total
        doc.setFontSize(12);
        doc.text(`Subtotal: R$ ${clientData.price}`, pageWidth - 10, doc.lastAutoTable.finalY + 10, { align: "right" });
        doc.setFont("helvetica", "bold");
        doc.text(`Total: R$ ${clientData.price}`, pageWidth - 10, doc.lastAutoTable.finalY + 16, { align: "right" });
        doc.setFont("helvetica", "normal");

        // Salvar o PDF
        doc.save(`OrdemServico-${clientData.uuid}.pdf`);
    }

    return {
        generateOrderServie
    }
}