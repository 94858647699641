import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel } from "@mui/material"
import { useMemo, useState } from "react";
import CustomEventTableRow from "./Row";

const EventsTable = ({
    columns,
    values
}) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    
    const page = 0
    const rowsPerPage = 50
    const formatColunsName = {
        createdAt: "Criada em",
        eventType: "Tipo",
        eventData: "Dados"
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const visibleRows = useMemo(
        () => stableSort(values, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage, values],
    );

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer >
                <Table aria-label="collapsible table" size="small">
                    <TableHead>
                        {columns.map((col, index) => {
                            return (
                                <TableCell
                                    key={index}
                                    sortDirection={orderBy === col ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === col}
                                        direction={orderBy === col ? order : 'asc'}
                                        onClick={createSortHandler(col)}
                                    >
                                        <strong>{formatColunsName[col]}</strong>
                                    </TableSortLabel>
                                </TableCell>
                            )
                        })}
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            return (
                                <CustomEventTableRow key={index} row={row} columns={columns}/>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default EventsTable;