import { Modal } from "@mui/material"

import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../action/modal"

import './styles.css'
import InputText from "../../Input/Text"
import { useState } from "react"

const PriceCalculatorModal = () => {
    const dispatch = useDispatch()
    const modalControler = useSelector(state => state.modal.priceCalculator)

    const [priceProvider, setPriceProvider] = useState()
    const [priceResult, setPriceResult] = useState()
    const [calculatingSolicitationPrice, setCalculatingSolicitationPrice] = useState(false)

    const onClose = () => {
        dispatch(openModal({modal: "priceCalculator", value: false}))
    }

    const handleCalculateSolicitationPrice = () => {
        setCalculatingSolicitationPrice(true)
        const cac = 5
        const keeperFee = 0.1
        const processorFee = 0.0499
        const processorFixedFee = 0.99

        const cogs = (priceProvider + cac) / (1-keeperFee)
        const price = (cogs + processorFixedFee) / (1-processorFee)

        setPriceResult(Number(price))
        setCalculatingSolicitationPrice(false)
    }

    const printPrice = (price) => {
        return Number(price).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
    }

    return (
        <Modal
            open={modalControler}
            onClose={onClose}
        >
            <div className="modal-price-calculator-container">
                <div className="modal-price-calculator-title-content">
                    <h2>Calculadora</h2>
                </div>
                <div className="modal-price-calculator-content">
                    <div className="new-value-content">
                        <span>Defina o valor do serviço</span>
                        <InputText 
                            label="Qual valor negociado com o prestador?"
                            value={priceProvider}
                            onChange={(event) => setPriceProvider(Number(event.target.value))}
                            onBlur={handleCalculateSolicitationPrice}
                            style={{
                                marginTop: 8
                            }}
                        />
                        <span>
                            {priceResult > 0 ? (
                                <span>{`Valor final do serviço ${printPrice(priceResult)}`}</span>
                            ) : calculatingSolicitationPrice && (
                                <span>Calculando...</span>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PriceCalculatorModal