import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        redirectService: false,
        priceCalculator: false,
        orderService: false
    },
    reducers: {
        openModal: (state, action) => {
            const { payload } = action
            state[payload.modal] =  payload.value
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    openModal
} = modalSlice.actions

export default modalSlice.reducer