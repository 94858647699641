import api from '../services/api';

export const getAllActiveSolicitations = async () => {
    try {
        const response = await api.get('/solicitation/AllActive')
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getAllActiveSolicitations', error.message)
        return false
    }
}

export const getAllSolicitations = async (config) => {
    try {
        const response = await api.post('/solicitation/all', config)
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getAllSolicitations', error.message)
        return false
    }
}

export const restartSolicitation = async (uuidSolicitation) => {
    try {
        const response = await api.post('/solicitation/lookingForProvider ', {uuidSolicitation: uuidSolicitation})
        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        console.log('restartSolicitation', error.message)
        return false
    }
}

export const finishSolicitation = async (uuidSolicitation) => {
    try {
        const response = await api.post('/solicitation/finish', {
            uuid: uuidSolicitation,
            rate: 5
        })
        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        console.log('finishSolicitation', error.message)
        return false
    }
}

export const getServiceProposal = async (uuidSolicitation) => {
    try {
        const response = await api.get('/serviceProposals/getBySolicitation', {params: {
            uuidSolicitation: uuidSolicitation
        }})
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getServiceProposal', error.message)
        return false
    }
}

export const cancelSolicitation = async (solicitation) => {
    try {
        const response = await api.post('/solicitation/cancel', {solicitation: solicitation})
        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        console.log('cancelSolicitation', error.message)
        return false
    }
}

export const setSolicitation = async (solicitation) => {
    try {
        const response = await api.post('/solicitation', solicitation)

        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('setSolicitation',error)
        return false
    }
}

export const redirectSolicitation = async (payload) => {
    try {
        const response = await api.post('/solicitation/redirectSolicitation', payload)
        if (response.status === 200) {
            return true
        }
        return false
    } catch (error) {
        console.log('redirectSolicitation', error.message)
        return false
    }
}