import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'

import * as leadService from '../../../services/leads'
import { loadLeads, selectLead, updateLead } from "../../../action/leads"

import Layout from "../../../components/Layout"
import PaperLead from "../../../components/Paper/Leads"
import EventsTable from "../../../components/EventsTable"

import './styles.css'
import Button from "../../../components/Button"

const Leads = () => {
    const dispatch = useDispatch()
    const leads = useSelector(state => state.lead.list)
    const leadSelected = useSelector(state => state.lead.selected)
    const refreshLeads = useSelector(state => state.lead.refresh)

    const [loading, setLoading] = useState(false)

    const refreshEventData = async () => {
        setLoading(true)
        const response = await leadService.refreshEvent(leadSelected.uuid)
        if (response) {
            dispatch(updateLead({
                column: "events",
                value: response
            }))
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refreshLeads) {
            (async() => {
                const response = await leadService.getLeads()
                if (response) dispatch(loadLeads(response))
            })()
        }
    }, [refreshLeads])

    return (
        <Layout>
            <div className="leads-container">
                <div className="leads-menu-content">
                    {!refreshLeads ? (
                        <>
                            <div className="leads-menu-item">
                                <span className="leads-menu-title">Leads:</span>
                                <div className="leads-menu-item-content">
                                    {leads.filter(item => !Object.keys(item).includes("cellphone")).map((lead, index) => {
                                        return (
                                            <PaperLead 
                                                key={index} 
                                                lead={lead} 
                                                active={leadSelected.uuid === lead.uuid} 
                                                onClick={() => dispatch(selectLead(lead))}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="leads-menu-item" style={{marginTop: 8}}>
                                <span className="leads-menu-title">Leads Quentes:</span>
                                <div className="leads-menu-item-content">
                                    {leads.filter(item => Object.keys(item).includes("cellphone")).map((lead, index) => {
                                        return (
                                            <PaperLead 
                                                key={index} 
                                                lead={lead} 
                                                active={leadSelected.uuid === lead.uuid} 
                                                onClick={() => dispatch(selectLead(lead))}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="leads-menu-item">
                            <span className="leads-menu-title">Carregando...</span>
                        </div>
                    )}
                </div>
                <div className="leads-event-content">
                    {Object.keys(leadSelected).length > 0 ? (
                        <>
                            <Button 
                                loading={loading}
                                label="Atualizar dados" 
                                kind="secondary" 
                                onClick={refreshEventData}
                                style={{marginBottom: 16}} 
                            />
                            <EventsTable 
                                values={leadSelected.events}
                                columns={[
                                    "createdAt",
                                    "eventType",
                                    "eventData"
                                ]}
                            />
                        </>
                    ) : (
                        <span>Selecione um lead</span>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Leads