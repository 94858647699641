import api from '../services/api';

export const getLeads = async () => {
    try {
        const response = await api.get('/leads/')
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log('getUserInformation', error.message)
        return false
    }
} 

export const refreshEvent = async (uuidLead) => {
    try {
        const response = await api.get('/leads/refreshEvent', {
            params: { uuid: uuidLead }
        })
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log('getUserInformation', error.message)
        return false
    }
} 