import { createSlice } from '@reduxjs/toolkit'

export const orderServiceSlice = createSlice({
    name: "orderService",
    initialState: {
        order: {},
    },
    reducers: {
        setOrder: (state, action) => {
            state.order = action.payload
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    setOrder,
    setUser
} = orderServiceSlice.actions

export default orderServiceSlice.reducer