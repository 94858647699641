import { useState } from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { openModal } from "../../../action/modal"

import './styles.css'
import Button from "../../Button"
import PaperSolicitationSummury from "../../Paper/SolicitationSummury"
import * as solicitationService from '../../../services/solicitations'
import InputText from "../../Input/Text"

const RedirectServiceModal = () => {
    const dispatch = useDispatch()

    const provider = useSelector(state => state.providers.selected)
    const modalActive = useSelector(state => state.modal.redirectService)
    const solicitationsActive = useSelector(state => Object.values(state.solicitations.list))

    const [isScheduled, setIsScheduled] = useState()
    const [solicitation, setSolicitation] = useState()
    const [loadingButotn, setLoadingButton] = useState(false)
    const [solicitationPrice, setSolicitationPrice] = useState()
    const [calculatingSolicitationPrice, setCalculatingSolicitationPrice] = useState(false)

    const paymentPlace = [
        {id: "onsite", label: "No local"},
        {id: "online", label: "Online"},
    ]

    const paymentMethods = [
        {id: "pix", label: "Pix"},
        {id: "credit_card", label: "Crédito"},
    ]

    const isScheduledOption = [
        {id: true, label: "Sim"},
        {id: false, label: "Não"},
    ]

    const handleClose = () => {
        dispatch(openModal({modal: "redirectService", value: false}))
    }

    const handleChangeSolicitation = (column, value) => {
        setSolicitation({
            ...solicitation,
            [column]: value
        })
    }

    const handleRedirectSolicitation = async () => {
        setLoadingButton(true)
        const response = await solicitationService.redirectSolicitation({
            solicitation: solicitation,
            isScheduled: isScheduled,
            provider: provider,
        })
        
        if (response) {
            window.location.href = '/solicitacoes'
        }
        setLoadingButton(false)
    }

    const handleCalculateSolicitationPrice = () => {
        setCalculatingSolicitationPrice(true)
        const cac = 5
        const keeperFee = 0.1
        const processorFee = 0.0499
        const processorFixedFee = 0.99

        const cogs = (solicitationPrice + cac) / (1-keeperFee)
        const price = (cogs + processorFixedFee) / (1-processorFee)

        setSolicitation({
            ...solicitation,
            price: Number(price.toFixed(2)),
            fee: Number((price - solicitationPrice).toFixed(2))
        })

        setCalculatingSolicitationPrice(false)
    }

    const printPrice = (price) => {
        return Number(price).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
    }

    return (
        <Modal
            open={modalActive}
            onClose={handleClose}
        >
            <div className="modal-redirect-service-container">
                <div className="modal-redirect-service-title-content">
                    <h2>Redirecionar Serviço</h2>
                </div>
                <div className="modal-redirect-service-content">
                    <div className="modal-redirect-service-title">
                        <span>Solicitações Ativas:</span>
                    </div>
                    <div className="modal-redirect-service-list">
                        {solicitationsActive.map((item, index) => {
                            return (
                                <>
                                    <PaperSolicitationSummury 
                                        key={index}
                                        active={solicitation && item.uuid === solicitation.uuid}
                                        solicitation={item} 
                                        onSelected={() => setSolicitation(item)}
                                    />
                                </>
                            )
                        })}
                    </div>
                    {solicitation &&
                        <>
                            <div className="new-value-content">
                                <span>Onde irá realizar o pagamento:</span>
                                <div className="new-value-button-group">
                                    {paymentPlace.map((paymentPlace) => {
                                        return (
                                            <button 
                                                key={paymentPlace.id}
                                                className={`new-value-button-button ${solicitation.paymentPlace === paymentPlace.id && "active"}`}
                                                onClick={() => handleChangeSolicitation("paymentPlace", paymentPlace.id)}
                                            >
                                                {paymentPlace.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="new-value-content">
                                <span>Forma de pagamento:</span>
                                <div className="new-value-button-group">
                                    {paymentMethods.map((paymentMethod) => {
                                        return (
                                            <button 
                                                key={paymentMethod.id}
                                                className={`new-value-button-button ${solicitation.paymentMethod === paymentMethod.id && "active"}`}
                                                onClick={() => handleChangeSolicitation("paymentMethod", paymentMethod.id)}
                                            >
                                                {paymentMethod.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="new-value-content">
                                <span>Defina o valor do serviço</span>
                                <InputText 
                                    label="Qual valor negociado com o prestador?"
                                    value={solicitationPrice}
                                    onChange={(event) => setSolicitationPrice(Number(event.target.value))}
                                    onBlur={handleCalculateSolicitationPrice}
                                    style={{
                                        marginTop: 8
                                    }}
                                />
                                <span>
                                    {solicitation.price > 0 ? (
                                        <span>{`Valor final do serviço ${printPrice(solicitation.price)}`}</span>
                                    ) : calculatingSolicitationPrice && (
                                        <span>Calculando...</span>
                                    )}
                                </span>
                            </div>
                            <div className="new-value-content">
                                <span>O serviço está agendado?</span>
                                <div className="new-value-button-group">
                                    {isScheduledOption.map((option) => {
                                        return (
                                            <button 
                                                key={option.id}
                                                className={`new-value-button-button ${isScheduled === option.id && "active"}`}
                                                onClick={() => setIsScheduled(option.id)}
                                            >
                                                {option.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="paper-solicitation-summury-footer">
                                <Button 
                                    loading={loadingButotn}
                                    disabled={!solicitation}
                                    label={"Redirecionar"}
                                    style={{width: "100%"}}
                                    onClick={handleRedirectSolicitation}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default RedirectServiceModal
