import api from '../services/api';

export const getUserInformation = async (uuidSolicitation) => {
    try {
        const response = await api.get('/user/getUser/', {params: {
            uuidSolicitation: uuidSolicitation
        }})
        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getUserInformation', error.message)
        return false
    }
}