import { createSlice } from '@reduxjs/toolkit'

export const leadSlice = createSlice({
    name: "lead",
    initialState: {
        name: "",
        list: [],
        selected: {},
        refresh: true
    },
    reducers: {
        setLeadName: (state, action) => {
            state.name = action.payload
        },
        setLastServiceSelected: (state, action) => {
            state.lastServiceSelected = action.payload
        },
        loadLeads: (state, action) => {
            state.list = action.payload
            state.refresh = false
        },
        selectLead: (state, action) => {
            state.selected = action.payload
        },
        updateLead: (state, action) => {
            const { payload } = action
            state.selected[payload.column] = payload.value
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    loadLeads,
    selectLead,
    updateLead,
    setLeadName,
    setLastServiceSelected
} = leadSlice.actions

export default leadSlice.reducer