import { useEffect, useState } from "react";
import InputText from "../../../../components/Input/Text";
import InputSelect from "../../../../components/Input/Select";

import { banks } from "./banks";

import './styles.css'
import Button from "../../../../components/Button";
import { useDispatch } from "react-redux";
import { changeCompany } from "../../../../action/companies";
import * as companiesService from '../../../../services/comapnies'

const Company = ({
    company
}) => {
    const dispatch = useDispatch()

    const [disableInput, setDisableIntpu] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)

    const handleUpdateCompany = async () => {
        setLoadingButton(true)

        const response = await companiesService.updateCompany(company)
        if (response) {
            window.location.reload()
        }

        setLoadingButton(false)
    }

    return (
        <div className="company-forms-container shadow">
            <div>
                <div className="company-forms-title-content">
                    <span>Dados da Empresa</span>
                    <button onClick={() => setDisableIntpu(false)}>Editar</button>
                </div>
                <div className="company-forms-input-group-3">
                    <InputText 
                        disabled={disableInput}
                        label="Nome da empresa"
                        value={company.name}
                        onChange={(event) => dispatch(changeCompany({
                            column: "name",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="CPF/CNPJ"
                        value={company.identity}
                        onChange={(event) => dispatch(changeCompany({
                            column: "identity",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Telefone"
                        value={company.tell}
                        onChange={(event) => dispatch(changeCompany({
                            column: "tell",
                            value: event.target.value
                        }))}
                    />
                </div>
                <div className="company-forms-input-address">
                    <InputText 
                        disabled={disableInput}
                        label="Rua"
                        value={company.address.street}
                        onChange={(event) => dispatch(changeCompany({
                            column: "street",
                            key: "company.address",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Número"
                        value={company.address.number}
                        onChange={(event) => dispatch(changeCompany({
                            column: "number",
                            key: "company.address",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Complemento"
                        value={company.address.complement}
                        onChange={(event) => dispatch(changeCompany({
                            column: "number",
                            key: "company.complement",
                            value: event.target.value
                        }))}
                    />
                </div>
                <div className="company-forms-input-group-3">
                    <InputText 
                        disabled={disableInput}
                        label="Bairro"
                        value={company.address.district}
                        onChange={(event) => dispatch(changeCompany({
                            column: "district",
                            key: "company.complement",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Cidade"
                        value={company.address.city}
                        onChange={(event) => dispatch(changeCompany({
                            column: "city",
                            key: "company.complement",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Estado"
                        value={company.address.state}
                        onChange={(event) => dispatch(changeCompany({
                            column: "state",
                            key: "company.complement",
                            value: event.target.value
                        }))}
                    />
                </div>
            </div>
            <div>
                <span className="company-forms-input-subtitle">Dados Bancários</span>
                <div className="company-forms-input-bank-account" style={{marginTop: 8}}>
                    <InputSelect 
                        label="Banco"
                        options={banks}
                        disabled={disableInput}
                        value={company.banckAccount.bank}
                        onChange={(event) => dispatch(changeCompany({
                            column: "bank",
                            key: "company.banckAccount",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Agencia"
                        value={company.banckAccount.branch_number}
                        onChange={(event) => dispatch(changeCompany({
                            column: "branch_number",
                            key: "company.banckAccount",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Conta"
                        value={company.banckAccount.account_number}
                        onChange={(event) => dispatch(changeCompany({
                            column: "account_number",
                            key: "company.banckAccount",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="Dígito"
                        value={company.banckAccount.account_check_digit}
                        onChange={(event) => dispatch(changeCompany({
                            column: "account_check_digit",
                            key: "company.banckAccount",
                            value: event.target.value
                        }))}
                    />
                </div>
                <div className="company-forms-input-group-2">
                    <InputText 
                        disabled={disableInput}
                        label="Titular"
                        value={company.banckAccount.holder_name}
                        onChange={(event) => dispatch(changeCompany({
                            column: "holder_name",
                            key: "company.banckAccount",
                            value: event.target.value
                        }))}
                    />
                    <InputText 
                        disabled={disableInput}
                        label="CPF/CNPJ Titular"
                        value={company.banckAccount.holder_document}
                        onChange={(event) => dispatch(changeCompany({
                            column: "holder_document",
                            key: "company.banckAccount",
                            value: event.target.value
                        }))}
                    />
                </div>
            </div>
            <div>
                <span className="company-forms-input-subtitle">Administradores</span>
                {company.managers.map((manager, index) => {
                    return (
                        <>
                            <div className="company-forms-input-group-3" key={index} style={{marginTop: 8}}>
                                <InputText 
                                    disabled={disableInput}
                                    label="Nome"
                                    value={manager.name}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "name",
                                        key: "manager",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="E-mail"
                                    value={manager.email}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "email",
                                        key: "manager",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="Telefone"
                                    value={manager.tell}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "tell",
                                        key: "manager",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                            </div>
                            <div className="company-forms-input-group-3">
                                <InputText 
                                    disabled={disableInput}
                                    label="CPF"
                                    value={manager.identity}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "identity",
                                        key: "manager",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="Data de nascimento"
                                    value={manager.birthdate}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "birthdate",
                                        key: "manager",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                            </div>
                            <div className="company-forms-input-address">
                                <InputText 
                                    disabled={disableInput}
                                    label="Rua"
                                    value={manager.address.street}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "street",
                                        key: "manager.address",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="Número"
                                    value={manager.address.number}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "number",
                                        key: "manager.address",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="Complemento"
                                    value={manager.address.complement}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "number",
                                        key: "manager.address",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                            </div>
                            <div className="company-forms-input-group-3">
                                <InputText 
                                    disabled={disableInput}
                                    label="Bairro"
                                    value={manager.address.district}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "district",
                                        key: "manager.address",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="Cidade"
                                    value={manager.address.city}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "city",
                                        key: "manager.address",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                                <InputText 
                                    disabled={disableInput}
                                    label="Estado"
                                    value={manager.address.state}
                                    onChange={(event) => dispatch(changeCompany({
                                        column: "state",
                                        key: "manager.address",
                                        index: index,
                                        value: event.target.value
                                    }))}
                                />
                            </div>
                        </>
                    )
                })}
            </div>
            {!disableInput &&
                <div className="company-forms-button-content">
                    <Button 
                        label="Salvar"
                        loading={loadingButton}
                        onClick={handleUpdateCompany}
                    />
                </div>
            }
        </div>
    )
}
export default Company;