import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../hooks/useAuth';
import { jwtDecode } from "jwt-decode";
import { setWebSocketConnection, setWebSocketReady } from '../../action/webScocket';
import { useDispatch } from 'react-redux';
import { updateProvider } from '../../action/providers';
import * as webSocket from '../../services/wss'

import Brand from '../../assets/gif/brand.gif'

import './styles.css'

const Auth = () => {
    const { login, logout } = useAuth()

    const dispatch = useDispatch()

    const startWebsocket = (user) => {
        const connection = webSocket.connect(user, "admin")
        connection.onopen = () => { dispatch(setWebSocketReady(true)); };

        connection.onmessage = async (event) => {
            const data = JSON.parse(event.data)

            if (data.action === "sendLocation") {
                dispatch(updateProvider(data))
            }
        }

        connection.onclose = async () => {
            logout()
        }
        
        dispatch(setWebSocketConnection(connection))
    }

    const handleAuth = (credentialResponse) => {
        login(credentialResponse)
        
        const user = jwtDecode(credentialResponse.credential)
        startWebsocket(user.email)
    }

    return (
        <div className='auth-container'>
            <div className='auth-content'>
                <img src={Brand} alt="logo"/>
                <GoogleLogin
                    useOneTap
                    onSuccess={credentialResponse => handleAuth(credentialResponse)}
                    onError={() => console.log('Login Failed')}
                />
            </div>
        </div>
    );
}

export default Auth;