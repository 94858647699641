import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'

import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { selecteCompany } from '../../../action/companies'

const PaperCompany = ({
    company,
    active
}) => {
    const dispatch = useDispatch()

    const companySelected = useSelector(state => state.companies.selected)

    const servicesIcon = {
        "Mecânico": MecanicoIcon,
        "Carga de Bateria": BateriaIcon,
        "Chaveiro": ChaveiroIcon,
        "Combustível": CombustivelIcon,
        "Troca de Pneus": PneusIcon,
        "Reboque": ReboqueIcon
    }

    const handleOnSelectedCompany = () => {
        dispatch(selecteCompany(company))
    }

    return (
        <div className={`paper-company-container shadow ${active ? "active": "inactive"} ${companySelected.uuid === company.uuid ? "selected":"unselected"}`} onClick={handleOnSelectedCompany}>
            <div className='paper-company-title-content'>
                <span className='paper-company-name'>{company.name}</span>
                <div className='paper-company-providers-count'>
                    <span>Prestadores</span>
                    <label>{`(${company.providersCount})`}</label>
                </div>
            </div>
            <div className='paper-company-services'>
                {company.services.length > 0 ? (
                    company.services.map((service, index) => {
                        return <img src={servicesIcon[service.service]} alt={service.service} key={index}/>
                    })
                ) : (
                    <span className='paper-comapny-services-empty'>Sem serviços cadastrados</span>
                )}
            </div>
        </div>
    )
}

export default PaperCompany