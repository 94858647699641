import Avatar from '@mui/material/Avatar';

const CustomAvatar = ({label, size}) => {
    return (
        <Avatar sx={size && { width: size, height: size }}>
            {label[0]}
        </Avatar>
    )
}

export default CustomAvatar