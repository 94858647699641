import { createSlice } from '@reduxjs/toolkit'

export const companiesSlice = createSlice({
    name: "companies",
    initialState: {
        active: [],
        inactive: [],
        selected: {}
    },
    reducers: {
        loadCompanies: (state, action) => {
            const { payload } = action 
                        
            state.active = payload.active
            state.inactive = payload.inactive
        },
        selecteCompany: (state, action) => {
            state.selected = action.payload
        },
        changeCompany: (state, action) => {
            const { payload } = action
            
            if (payload.key === "company.address") {
                state.selected.address[payload.column] = payload.value
            } else if (payload.key === "company.banckAccount") {
                state.selected.banckAccount[payload.column] = payload.value
            } else if (payload.key === "manager") {
                state.selected.managers[payload.index][payload.column] = payload.value
            } else if (payload.key === "manager.address") {
                state.selected.managers[payload.index].address[payload.column] = payload.value
            } else {
                state.selected[payload.column] = payload.value
            }
        }
    }
})
// Action creators are generated for each case reducer function
export const { 
    loadCompanies,
    selecteCompany,
    changeCompany
} = companiesSlice.actions

export default companiesSlice.reducer