import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './routes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'

import { AuthProvider } from './hooks/useAuth';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
);
reportWebVitals();
