import api from "./api";

export const registerEvent = async (event) => {
    try {
        const response = await api.post('/leads/registerEvent', event)

        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log("registerEvent", error)
    }
}