import api from '../services/api';

export const createPaymentLink = async (uuidSolicitation) => {
    try {
        const response = await api.get('/payments/link', {
            params: {uuidSolicitation}
        })
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log('createPaymentLink', error.message)
        return false
    }
}