import { useDispatch, useSelector } from "react-redux";
import InputText from "../../../components/Input/Text";
import Layout from "../../../components/Layout";

import './styles.css'
import { changeCurrentLocation, changeDestinyLocation, changeKeyConfig, changePrice, changeSolicitation, changeTiresConfig, changeVehicle, changeWheelsConditions } from "../../../action/newSolicitations";
import InputSelect from "../../../components/Input/Select";
import { useEffect, useState } from "react";
import { consultVehicle } from '../../../services/vehicles';
import { setLeadName } from "../../../action/leads";
import { loadServices } from "../../../action/services";
import api from "../../../services/api";
import Button from "../../../components/Button";
import { setSolicitation } from '../../../services/solicitations';

import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'

const NewSolicitation = () => {
    const dispatch = useDispatch()

    const lead = useSelector(state => state.lead)
    const solicitation = useSelector(state => state.newSolicitation)
    const servicesState = useSelector(state => state.services.auto)

    const [locationError, setLocationError] = useState(false)
    const [destinyLocationError, setDestinyLocationError] = useState(false)
    const [loadginCreateSolicitation, setLoadingCreateSolicitation] = useState(false)
    const [formsError, setErro] = useState({
        name: {error: false, message: "Nome deve conter pelo menos 4 caracteres."},
        placa: {error: false, message: "Placa inválida"},
        address: {error: false, message: "Endereço inválido"},
        destiny: {error: false, message: "Endereço inválido"},
        email: {error: false, message: "E-mail inválido"}
    })

    const services = [
        {id: "bateria", label: "Socorro Bateria", icon: BateriaIcon},
        {id: "pneu", label: "Troca de Pneus", icon: PneusIcon},
        {id: "reboque", label: "Reboque", icon: CombustivelIcon},
        {id: "chaveiro", label: "Chaveiro", icon: ChaveiroIcon},
        {id: "mecanico", label: "Mecânico", icon: MecanicoIcon},
        {id: "combustivel", label: "Combustível", icon: CombustivelIcon},
    ]

    const tireConditionsOptions = [
        {id: "furado", label: "Furado"},
        {id: "rasgado", label: "Rasgado"},
        {id: "estourado", label: "Estourado"}
    ]

    const hasSteppeOptions = [
        {id: "sim", label: "Sim"},
        {id: "não", label: "Não"},
    ]

    const keyConditionsOptions = [
        {id: "quebrada", label: "Quebrada"},
        {id: "inVehicle", label: "Dentro do veículo"}
    ]

    const hasExtraKeyOptions = [
        {id: "sim", label: "Sim"},
        {id: "não", label: "Não"},
    ]

    const regex = {
        name: /^[a-zA-Z\u00C0-\u017F´]{4,}(?: [a-zA-Z\u00C0-\u017F´]+){0,}$/,
        placa: /^[A-Z]{3}[0-9][A-Z0-9][0-9]{2}$/i,
        email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    }

    const wheelsConditionsOptions = [
        {id: "lockedWheels", label: "As rodas estão travadas"},
        {id: "freeWheels", label: "As rodas estão livres"}
    ]

    const printJson = () => {
        return JSON.stringify({
            lead: lead,
            solicitation: solicitation,
        }, null, 4)
    }

    const formatAddress = (address) => {
        const street = address.filter(item => item.types.includes("route")).map(item => item.short_name)[0]
        const streetNumber = address.filter(item => item.types.includes("street_number")).map(item => item.short_name)[0]
        const district = address.filter(item => item.types.includes("sublocality_level_1")).map(item => item.short_name)[0]
        const city = address.filter(item => item.types.includes("administrative_area_level_2")).map(item => item.short_name)[0]
        const state = address.filter(item => item.types.includes("administrative_area_level_1")).map(item => item.short_name)[0]

        return [street, streetNumber, district, city, state]
    }

    const formatVehicle = (vehicle) => {
        return `${vehicle.brand} - ${vehicle.model} ${vehicle.year}/${vehicle.yearModel} - ${vehicle.color}`
    }

    const handleGetLocation = async (location) => {
        const currentLocation = location === "current_location" ? solicitation.current_location.formattedAddress : solicitation.destinyLocation.formattedAddress
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${currentLocation}&key=AIzaSyCBFaUHIxOMqWmpSBrrWaF3uJbNDPSeXKE`

        const response = await fetch(new Request(url)).then((response) => response.json())

        if (response.status === "OK") {
            setLocationError(false)
            const result = response.results[0]

            const addressComponents = result.address_components
            const geometry = result.geometry

            const address = formatAddress(addressComponents)
            dispatch(changeSolicitation({
                column: location, 
                value: {
                    street: address[0],
                    streetNumber: address[1],
                    district: address[2],
                    city: address[3],
                    state: address[4],
                    latitude: Number(geometry.location.lat),
                    longitude: Number(geometry.location.lng),
                    formattedAddress: result.formatted_address
                }
            }))
        } else {
            if (location === "current_location") setLocationError(true)
            else setDestinyLocationError(true)
        }
    }

    const handleConsultVehicle = async () => {
        setErro({ ...formsError, placa: { ...formsError.placa, error: false}})

        if (regex.placa.test(solicitation.placa)) {
            const response = await consultVehicle(solicitation.placa)
            if (response) {
                dispatch(changeVehicle({
                    service: solicitation.service,
                    vehicle: formatVehicle(response),
                    vehicleCategory: response.category,
                    vehicleOrigin: response.origin,
                    vehicleType: response.type
                }))
            } else {
                setErro({ ...formsError, placa: { ...formsError.placa, error: true}})
            }
        } else {
            setErro({ ...formsError, placa: { ...formsError.placa, error: true}})
        }
    }

    const checkName = () => {
        const text = lead.name.replace(/^\s+|\s+$/gm,'');

        if (text !== '' && regex.name.test(text)) {
            setErro({ ...formsError, name: { ...formsError.name, error: false}})
            return true
        }
        setErro({ ...formsError, name: { ...formsError.name, error: true}})
        return false
    }

    const getServicesActive = async () => {
        try {
            const response = await api.get('service/active')
            if (response.status === 200) {
                dispatch(loadServices(response.data))
            }
        } catch (error) {
            console.log("getServicesActive", error)
        }
    }

    const createSolicitation = async () => {
        setLoadingCreateSolicitation(true)
        const serviceSelected = services.filter(item => item.id === solicitation.service)[0].label
        const serviceAux = servicesState.filter(item => serviceSelected === item.name)[0]

        if (serviceAux.difficulty.includes(solicitation.difficulty)) {
            const response = await setSolicitation({solicitation: solicitation, leadName: lead.name})
            if (response.status === 200) {
                window.location.href = '/solicitacoes'
            }
        }
        setLoadingCreateSolicitation(false)
    }

    useEffect(() => {
        getServicesActive()
    }, [])

    return (
        <Layout>
            <div className="new-solicitation-container">
                <div className="new-solicitation-forms-content">
                    <div className="new-solicitations-type-service-content">
                        <span className="new-solicitations-subtitle">
                            Selecione um tipo de serviço:
                        </span>
                        <div className="new-solicitation-type-services-button-group">
                            {servicesState.length > 0 && services.map((service) => {
                                return (
                                    <button 
                                        key={service.id}
                                        className={`new-solicitation-type-services-button ${solicitation.service === service.id ? "active" : "inactive"}`}
                                        onClick={() => dispatch(changeSolicitation({
                                            column: "service",
                                            value: service.id
                                        }))}
                                    >
                                        {service.label}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="new-solicitation-basic-form-group">
                        <div className="new-solicitation-basic-form-item">
                            <span className="new-solicitations-subtitle">Qual a localização atual do veículo?</span>
                            <InputText 
                                value={solicitation.current_location.formattedAddress}
                                label={"Digite a localização atual do veículo"}
                                error={locationError}
                                helperText={formsError.address.message}
                                onBlur={() => handleGetLocation("current_location")}
                                onChange={(event) => dispatch(changeCurrentLocation({
                                    column:"formattedAddress", 
                                    value: event.target.value
                                }))}
                            />
                        </div>
                        {solicitation.service === "reboque" &&
                            <div className="new-solicitation-basic-form-item">
                                <span className="new-solicitations-subtitle">Qual destino do veículo?</span>
                                <InputText 
                                    value={solicitation.destinyLocation.formattedAddress}
                                    label={"Digite o destino do veículo"}
                                    error={destinyLocationError}
                                    helperText={formsError.destiny.message}
                                    onBlur={() => handleGetLocation("destinyLocation")}
                                    onChange={(event) => dispatch(changeDestinyLocation({
                                        column:"formattedAddress", 
                                        value: event.target.value
                                    }))}
                                />
                            </div>
                        }
                        <div className="new-solicitation-basic-form-item">
                            <span className="new-solicitations-subtitle">Qual a placa do veículo?</span>
                            <InputText 
                                maxLength={7}
                                value={solicitation.placa}
                                placeholder={"AAA0X00 ou AAA9999"}
                                label={"Digite a placa do seu veículo"}
                                onBlur={handleConsultVehicle}
                                error={formsError.placa.error}
                                helperText={formsError.placa.message}
                                onChange={(event) => dispatch(changeSolicitation({
                                    column: "placa", 
                                    value: String(event.target.value).toLocaleUpperCase()
                                }))}
                            />
                        </div>
                        <div className="new-solicitation-basic-form-item">
                            <span className="new-solicitations-subtitle">Qual o nome do cliente?</span>
                            <InputText 
                                value={lead.name}
                                onBlur={checkName}
                                error={formsError.name.error}
                                helperText={formsError.name.message}
                                label="Digite o nome do cliente"
                                onChange={(event) => dispatch(setLeadName(event.target.value))}
                            />
                        </div>
                        <div className="new-solicitation-basic-form-item">
                            <span className="new-solicitations-subtitle">Qual o telefone de contato com o cliente?</span>
                            <InputText 
                                mask="cellphone"
                                value={solicitation.cellphone}
                                label={"Digite o número de telefone do cliente"}
                                placeholder={"(DD) 99999-9999"}
                                onChange={(event) => dispatch(changeSolicitation({
                                    column: "cellphone", 
                                    value: event.target.value
                                }))}
                            />
                        </div>
                        <div className="new-solicitation-basic-form-item">
                            <span className="new-solicitations-subtitle">Breve relato do ocorrido:</span>
                            <InputText 
                                multiline
                                rows={2}
                                value={solicitation.report}
                                label={"Digite..."}
                                onChange={(event) => dispatch(changeSolicitation({
                                    column: "report", 
                                    value: event.target.value
                                }))}
                            />
                        </div>
                        {solicitation.service === "pneu" && 
                            <div className="new-solicitation-basic-form-item">
                                <span className="new-solicitations-subtitle">Como está a situação do pneu?</span>
                                <InputSelect 
                                    label="Qual a situação do pneu?"
                                    options={tireConditionsOptions}
                                    value={solicitation.tiresConfig.tire_conditions}
                                    style={{marginBottom: 6}}
                                    onChange={(event) => dispatch(changeTiresConfig({
                                        column: "tire_conditions", 
                                        value: event.target.value
                                    }))}
                                />
                                <InputSelect 
                                    label="Você possui ferramentas?"
                                    options={hasSteppeOptions}
                                    value={solicitation.tiresConfig.has_tools}
                                    style={{marginBottom: 6}}
                                    onChange={(event) => dispatch(changeTiresConfig({
                                        column: "has_tools", 
                                        value: event.target.value
                                    }))}
                                />
                                <InputSelect 
                                    label="Você possui estepe?"
                                    options={hasSteppeOptions}
                                    value={solicitation.tiresConfig.has_steppe}
                                    style={{marginBottom: 6}}
                                    onChange={(event) => dispatch(changeTiresConfig({
                                        column: "has_steppe", 
                                        value: event.target.value
                                    }))}
                                />
                                {solicitation.tiresConfig.has_steppe === "sim" &&
                                    <InputSelect 
                                        label="O estepe está cheio?"
                                        options={hasSteppeOptions}
                                        style={{marginBottom: 6}}
                                        value={solicitation.tiresConfig.steppe_is_full}
                                        onChange={(event) => dispatch(changeTiresConfig({
                                            column: "steppe_is_full", 
                                            value: event.target.value
                                        }))}
                                    />
                                }
                            </div>
                        }
                        {solicitation.service === "chaveiro" &&
                            <div className='new-solicitation-basic-item'>
                                <div className='new-solicitations-subtitle'>
                                    <span>Como está a situação das chaves?</span>
                                </div>
                                <InputSelect 
                                    label="Qual a situação da chave?"
                                    options={keyConditionsOptions}
                                    style={{marginBottom: 6}}
                                    value={solicitation.keyConfig.condition}
                                    onChange={(event) => dispatch(changeKeyConfig({
                                        column: "condition", 
                                        value: event.target.value
                                    }))}
                                />
                                {solicitation.keyConfig.condition === "quebrada" &&
                                    <InputSelect 
                                        label="Você possui chave reserva?"
                                        options={hasExtraKeyOptions}
                                        style={{marginBottom: 6, marginTop: 6}}
                                        value={solicitation.keyConfig.hasExtrakey}
                                        onChange={(event) => dispatch(changeKeyConfig({
                                            column: "hasExtrakey", 
                                            value: event.target.value
                                        }))}
                                    />
                                }
                            </div>
                        }
                        {solicitation.service === "reboque" &&
                            <div className='new-solicitation-basic-item'>
                                <div className='new-solicitations-subtitle'>
                                    <span>Como está a situação das rodas?</span>
                                </div>
                                <InputSelect 
                                    label="Qual a situação das rodas?"
                                    options={wheelsConditionsOptions}
                                    style={{marginBottom: 6}}
                                    value={solicitation.keyConfig.condition}
                                    onChange={(event) => dispatch(changeWheelsConditions({
                                        column: "condition", 
                                        value: event.target.value
                                    }))}
                                />
                            </div>
                        }
                    </div>
                    <div style={{display: 'flex', justifyContent: "flex-end", marginTop: 8}}>
                        <Button 
                            loading={loadginCreateSolicitation}
                            label="Criar solicitação"
                            onClick={createSolicitation}
                        />
                    </div>
                </div>
                <div className="new-solicitation-result-content">
                    <pre>{printJson()}</pre>
                </div>
            </div>
        </Layout>
    )
}

export default NewSolicitation;