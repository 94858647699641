import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { OutlinedInput } from '@mui/material';

const InputSelect = ({
    label,
    options,
    value,
    style,
    onChange,
    disabled
}) => {

    return (
        <FormControl fullWidth style={style}>
            <InputLabel size='small'>{label}</InputLabel>
            <Select 
                size="small"
                disabled={disabled}
                label={label}
                onChange={onChange}
                value={value}
                input={<OutlinedInput label={label} />}
            >
                {options.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default InputSelect;