import api from './api';

export const consultVehicle = async (placa) => {
    try {
        const response = await api.get('/vehicles', {params: {
            placa: placa
        }})

        if (response.status === 200) {
            return response.data
        }
        return false
    } catch (error) {
        console.log('getSolicitationsByUser', error.message)
        return false
    }
}